import React from 'react'

function Resume() {
    return (
        <section id="resume" className="container section">
            <div className="row">
                <div className="col-md-10">
                    <h2 id="resume_header" className="section__title">Resume_</h2>
                    <p className="section__description">
                        <p><strong>Shaping Ideas Into Technology.</strong></p>
                        <p>
                            Strong experience in Design, Develop and Execute projects.
                            With rapid development and everyday new technologies emerging, it is important to be on top of latest technologies. Therefore I believe in, "The measure of intelligence is the ability to change." and try to learn and be on top of it.
                        </p>
                        <p>Presenting new ideas and initiatives to management and lead initiatives with team members is another area of interest.</p>
                        <i>I don't "just" love seeing things being built. I like to actually build things myself.</i>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 section__resume resume-list">
                    <h3 className="resume-list_title">education</h3>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">Graphic Era University, India </p>
                        <p className="resume-list__block-date">2007 - 2010 | Master of Computer Applications</p>
                        <p>Organized college-fest stall and got my first successful entreprenuer experience.</p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">University of Delhi, India</p>
                        <p className="resume-list__block-date">2004 - 2007 | History Honors, B.A</p>
                        <p>
                            Joined college weight lifting team, played national Ball badminton, member of National Cadet Corps (NCC). This helped me becoming better team player, building team and working with different people.
                        </p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">Omkarananda Saraswati Nilayam School, India</p>
                        <p className="resume-list__block-date">2001 - 2003 | Science (Physics, Chemistry & Maths) </p>
                        <p>
                            Back-bencher. Left computer science (<i>Just to pursue in masters...</i>) and studied literature.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 section__resume resume-list">
                    <h3 className="resume-list_title">employment</h3>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">United Nations Federal Credit Union (UNFCU)</p>
                        <p className="resume-list__block-date">May 2019 - Present | USA</p>
                        <p>Senior Full Stack Developer</p>
                        <p>Developing high precision intuitive softwares for financial business requirements. Establishing best practices and processes.</p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">Nous Infosystems</p>
                        <p className="resume-list__block-date">Aug 2017 - May 2019 | USA</p>
                        <p>Software Programmer</p>
                        <p>Understanding business requirements, co-ordinating with offshore team, developing high quality solutions and client satisfaction.</p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">Nous Infosystems</p>
                        <p className="resume-list__block-date">Sep 2014 - Jul 2017 | India</p>
                        <p> Module Lead</p>
                        <p>Leading team, guiding them through technical and non-technical requirements and skills. Implementing complex modules, solving challenges varying from technology such as Azure (cloud) services and infrastructure, AngularJS with Dot Net Nuke and .NET framework to agile business requirements.</p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">RSS-Tech</p>
                        <p className="resume-list__block-date">Jul 2014 - Aug 2016 | India</p>
                        <p>
                            Co-founded tech startup. Delivered some amazing products for startups based out in Australia, Europe and India. Provided them boost to mark their presence on web and visualize their digital growth.
                         </p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">ABS LLC</p>
                        <p className="resume-list__block-date">Mar 2011 - Aug 2014 | India</p>
                        <p>Software Development Engineer</p>
                        <p>Played various roles like Developer, Scrum Master, Program Management & Delivery, Client handling & Sales (USA & India), Recruitment & Team building. 
                            Managing cross technical teams & mentoring new talents. 
                        </p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">Brandscapes Worldwide</p>
                        <p className="resume-list__block-date">Jun 2010 - Jul 2010 | India</p>
                        <p>Freelance project, right after college. Built interactive dashboard using Flash.</p>
                    </div>
                    <div className="resume-list__block">
                        <p className="resume-list__block-title">Oil and Natural Gas Corporation (ONGC) </p>
                        <p className="resume-list__block-date">Jan 2010 - Jun 2010 | India</p>
                        <p>
                            Industrial Project. Used scientific programming to process the seismic data & to generate digital signals for taking an Oil exploration & other key decisions.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Resume
