import './App.css';

import Menu from './Components/Menu';
import Header from './Components/Header';
import Hello from './Components/Hello';
import Resume from './Components/Resume';
import Skills from './Components/Skills';
import Recommendations from './Components/Recommendations';
import InterestingFacts from './Components/InterestingFacts';
import Contact from './Components/Contact';

function App() {
  return (
    <div className="app">
      <Menu />
      <Header />
      <Hello />
      <Resume />
      <Skills />
      {/* <Recommendations /> */}
      {/* <InterestingFacts /> */}
      <Contact />
    </div>
  );
}

export default App;
