import React from 'react'

function Hello() {
    return (
        <section id="hello" className="container section">
            <div className="row">
                <div className="col-md-10">
                    <h2 id="hello_header" className="section__title">Hello_</h2>
                    <p className="section__description">
                        I am Full Stack Engineer, build web solutions from the ground up - from concept, design,
                        architecture and programming to UX and SEO. Skilled at writing well-designed, testable and efficient code using
                        current best practices in Web development. Fast learner, hard worker and team player who is proficient in
                        an array of programming languages.
                    </p>
                    <p>Along with technical experience I also have years of experience in leading teams, mentor team members, hiring and building team. </p>
                    {/* <a href="" className="section_btn site-btn"><img src="assets/img/img_btn_icon.png" alt="" />Download CV</a> */}
                </div>
            </div>
        </section>
    )
}

export default Hello
