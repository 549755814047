import React from 'react'

function Skills() {
    return (
        <section id="skills" className="container section">
            <h2 id="resume_header" className="section__title">Skills_</h2>
            <div className="row section__resume progress-list js-progress-list">
                {/* <div className="col-md-12">
                    <h3 className="progress-list__title">general skills</h3>
                </div> */}
                <div className="col-md-5 mr-auto">
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">html5 | css3 | sass</span>
                            <span className="progress-list__skill-value">80%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '80%'}} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" >
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">node | javascript | jquery</span>
                            <span className="progress-list__skill-value">90%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '90%'}} role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">React | Pug | Handlebar | AngularJs | Kendo</span>
                            <span className="progress-list__skill-value">70%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '70%'}} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">Web api | WCF | Node API | Swagger</span>
                            <span className="progress-list__skill-value">80%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '80%'}} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">Azure | Firebase | AWS</span>
                            <span className="progress-list__skill-value">70%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '70%'}} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">Firestore | DocumentDB | CouchDB | Cassandra</span>
                            <span className="progress-list__skill-value">80%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '80%'}} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mr-auto">
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">bootstrap | materializecss | material-ui</span>
                            <span className="progress-list__skill-value">80%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '80%'}} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">grunt | gulp | webpack</span>
                            <span className="progress-list__skill-value">70%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '70%'}} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">git | Azure DevOps | Bitbucket</span>
                            <span className="progress-list__skill-value">90%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '90%'}} role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">ASP.NET Core | MVC</span>
                            <span className="progress-list__skill-value">80%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '80%'}} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">C# | OOPS</span>
                            <span className="progress-list__skill-value">80%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '80%'}} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                    <div className="progress-list__skill">
                        <p>
                            <span className="progress-list__skill-title">SQL | Postgres | Airtable | Oracle</span>
                            <span className="progress-list__skill-value">80%</span>
                        </p>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '80%'}} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>
    )
}

export default Skills
