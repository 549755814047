import React from 'react'

function Contact() {
    return (
        <div className="background">
            <div id="contact" className="container section">
                <div className="row">
                    <div className="col-md-12">
                        <p id="contacts_header" className="section__title">Get in touch_</p>
                    </div>
                </div>
                <div className="row contacts">
                    <div className="col-md-6">
                        <div className="contacts__list">
                            <dl className="contact-list">
                                <dt>Phone:</dt>
                                <dd><a href="tel:+18482195586">+1 848-219-5586</a></dd>
                                <dt>Email:</dt>
                                <dd><a href="mailto:ravindrasinghshah@gmail.com">ravindrasinghshah@gmail.com</a></dd>
                            </dl>
                        </div>
                        <div className="contacts__display">

                            <div className="contacts__qrcode">
                                <img src="/images/mycontact.png" />
                                <p>For a quick contact scan the QR code to get all my info</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contacts__form">
                            <p className="contacts__form-title">Or just write me a message here_</p>
                            <a href='https://forms.gle/z5zNRUGsU5WAHC369' target="_blank" className="btn site-btn">
                                Google Message
                                </a>
                            {/* <form className="js-form">
                                <div className="form-group">
                                    <input className="form-field js-field-name" type="text" placeholder="Your name" required />
                                    <span className="form-validation"></span>
                                    <span className="form-invalid-icon"><i className="mdi mdi-close" aria-hidden="true"></i></span>
                                </div>
                                <div className="form-group">
                                    <input className="form-field js-field-email" type="email" placeholder="Your e-mail" required />
                                    <span className="form-validation"></span>
                                    <span className="form-invalid-icon"><i className="mdi mdi-close" aria-hidden="true"></i></span>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-field js-field-message" placeholder="Type the message here" required></textarea>
                                    <span className="form-validation"></span>
                                    <span className="form-invalid-icon"><i className="mdi mdi-close" aria-hidden="true"></i></span>
                                </div>
                                <button className="site-btn site-btn--form" type="submit" value="Send">Send</button>
                            </form> */}
                        </div>
                        <div className="contacts__social">
                            <br />
                            <ul>
                                <li><a href="https://github.com/ravindrasinghshah" target="_blank" rel="noreferrer">GitHub</a></li>
                                <li><a href="https://www.linkedin.com/in/ravindrasinghshah" target="_blank" rel="noreferrer">Linkedin</a></li>
                                <li><a href="https://www.facebook.com/ravindrasinghshah1/" target="_blank" rel="noreferrer">Facebook</a></li>
                                <li><a href="https://www.instagram.com/ravindrasinghshah/" target="_blank" rel="noreferrer">Instagram</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="footer">
                        <p>© {new Date().getFullYear()} Ravindra Singh Shah. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
