import React from 'react'
import MenuIcon from '@material-ui/icons/Menu';
function Menu() {
    return (
        <div>
            <div className="menu">
                <div className="container">
                    <div className="row">
                        <div className="menu__wrapper d-none d-lg-block col-md-12">
                            <nav className="">
                                <ul>
                                    <li><a href="#hello">Hello</a></li>
                                    <li><a href="#resume">Resume</a></li>
                                    <li><a href="#skills">Skills</a></li>
                                    {/* <li><a href="#recommendations">Recommendations</a></li> */}
                                    {/* <li><a href="#interesting-facts">Interesting facts</a></li> */}
                                    <li><a href="#contact">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="menu__wrapper col-md-12 d-lg-none">
                            <button type="button" className="menu__mobile-button">
                                <span><MenuIcon /></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile menu */}
            <div className="mobile-menu d-lg-none">
                <div className="container">
                    <div className="mobile-menu__close">
                        <span><i className="mdi mdi-close" aria-hidden="true"></i></span>
                    </div>
                    <nav className="mobile-menu__wrapper">
                        <ul>
                            <li><a href="#hello">Hello</a></li>
                            <li><a href="#resume">Resume</a></li>
                            <li><a href="#skills">Skills</a></li>
                            {/* <li><a href="#recommendations">Recommendations</a></li> */}
                            <li><a href="#interesting-facts">Interesting facts</a></li>
                            <li><a href="#contact">Contact</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Menu
