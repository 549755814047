import React from 'react'

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import InstagramIcon from '@material-ui/icons/Instagram';

function Header() {
    return (
        <header className="main-header" style={{ backgroundImage: `url('/images/bg.jpg')` }}>
            <div className="container">
                <div className="row personal-profile">
                    <div className="col-md-4 personal-profile__avatar">
                        <img className="" src="https://avatars2.githubusercontent.com/u/8829028?s=460&u=0d60dc74714ab36e6e37de18a940353dabc5ae87&v=4" alt="avatar" />
                    </div>
                    <div className="col-md-8">
                        <p className="personal-profile__name">Ravindra Singh Shah_</p>
                        <p className="personal-profile__work">Sr. Full Stack Engineer, Tech Enthusiast</p>
                        <div className="personal-profile__contacts">
                            <dl className="contact-list contact-list__opacity-titles">

                                <dt>Phone:</dt>
                                <dd><a href="tel:+18482195586">+1 848-219-5586</a></dd>
                                <dt>Email:</dt>
                                <dd><a href="mailto:ravindrasinghshah@gmail.com">ravindrasinghshah@gmail.com</a></dd>
                                <dt>Address:</dt>
                                <dd>Manhattan, New York</dd>
                            </dl>
                        </div>
                        <p className="personal-profile__social">
                            <a href="https://github.com/ravindrasinghshah" target="_blank" rel="noreferrer"><GitHubIcon /></a>
                            <a href="https://www.linkedin.com/in/ravindrasinghshah" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
                            <a href="https://www.facebook.com/ravindrasinghshah1/" target="_blank" rel="noreferrer"><FacebookIcon /></a>
                            <a href="https://www.instagram.com/ravindrasinghshah/" target="_blank" rel="noreferrer"><InstagramIcon /></a>
                        </p>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
